var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-content',{attrs:{"loading":_vm.loading,"id":"baseContentTop"},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.content)?_c('v-container',{attrs:{"fluid":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.content[0].title))])])],1)],1):_vm._e()]},proxy:true},{key:"content",fn:function(){return [(_vm.content)?_c('v-container',{attrs:{"id":"scrollArea","fluid":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":_vm.dynamicFlex(),"order-md":"1","order":"2","order-sm":"2"}},[(_vm.content[0].content)?_c('div',{staticClass:"dynamic-content",domProps:{"innerHTML":_vm._s(_vm.renderToHtml(_vm.content[0].content))},on:{"click":_vm.handleClicks}}):_vm._e(),_c('toggle',{staticClass:"mt-10",attrs:{"toggleOn":"By Category","toggleOff":"By Year","name":"meetings"}}),(_vm.displayMode.message === 'By Category')?_c('div',_vm._l((_vm.$store.getters.config.strapiEnums
                  .publications),function(category){return _c('div',{key:category.enum},[_c('h2',{staticClass:"mt-8",attrs:{"id":category.slug}},[_vm._v(_vm._s(category.title))]),_vm._v(" "+_vm._s(category.description)+" "),_c('ListTablePublication',{staticClass:"mt-8",class:{
                    'pl-6':
                      _vm.$vuetify.breakpoint.md ||
                      _vm.$vuetify.breakpoint.lg ||
                      _vm.$vuetify.breakpoint.xl,
                    'pr-6':
                      _vm.$vuetify.breakpoint.md ||
                      _vm.$vuetify.breakpoint.lg ||
                      _vm.$vuetify.breakpoint.xl
                  },attrs:{"items":_vm.filterPublicationData(category.enum),"hideCategory":true}})],1)}),0):_vm._e(),(_vm.displayMode.message === 'By Year')?_c('div',[_c('ListTablePublication',{staticClass:"mt-8",class:{
                  'pl-6':
                    _vm.$vuetify.breakpoint.md ||
                    _vm.$vuetify.breakpoint.lg ||
                    _vm.$vuetify.breakpoint.xl,
                  'pr-6':
                    _vm.$vuetify.breakpoint.md ||
                    _vm.$vuetify.breakpoint.lg ||
                    _vm.$vuetify.breakpoint.xl
                },attrs:{"items":_vm.publicationsSorted}})],1):_vm._e()],1),(_vm.showToc && _vm.displayMode.message === 'By Category')?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2","order-md":"2","order":"1","order-sm":"1"}},[_c('TOC',{attrs:{"selector":"#scrollArea","top":"#baseContentTop","tocHeading":"Categories"}})],1):_vm._e()],1)],1):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }